<template>
    <div class="box">
        <div class="password">
            <div class="password-header">
                <div>鑫易付代理商-更改密码</div>
            </div>
            <div class="password-main">
                <el-form :model="form">
                    <el-form-item>
                        <el-input type="text" v-model="form.phone" placeholder="请输入账号"
                            @blur="queryMerchantsNO(form.phone)" />
                    </el-form-item>
                    <el-form-item>
                        <el-input type="password" v-model="form.oldPassword" show-password :auto-insert-space="true"
                            placeholder="请输入旧密码" />
                    </el-form-item>
                    <el-form-item>
                        <el-input type="password" v-model="form.newPassword" show-password :auto-insert-space="true"
                            placeholder="请输入新密码" />
                    </el-form-item>
                    <el-form-item>
                        <el-input type="password" v-model="form.checkNewPassword" show-password :auto-insert-space="true"
                            placeholder="确认新密码" />
                    </el-form-item>
                    <el-from-item>
                        <el-button color="#fff" :dark="true" size="large" type="primary" @click="onSubmit"
                            v-loading="isLoading">{{ loginText }}
                        </el-button>
                    </el-from-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
 import { ElMessage } from 'element-plus';
export default {
    data() {
        return {
            form: {
                oldPassword:'',
                newPassword: '',
                checkNewPassword:''
            },
            loginText: '确 定',
            isLoading: false,
        }
    },
    methods: {
        async queryMerchantsNO(phone) {
            if (!(/^1[3456789]\d{9}$/.test(phone))) {
                ElMessage({ message: `请输入正确的手机号`, type: 'warning', })
                return false;
            } else {
                this.$api.queryno({ phone: phone }).then(res => {
                    if (res.status == 200 && res.success) {
                        this.option = res.data
                    } else {
                        ElMessage({ message: `${res.error.message}`, type: 'warning', })
                    }
                })
            }
        },
    }
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom left, #2d3e69, #4d6fbf);
    overflow: hidden;

    .password {
        width: 400px;
        height: 350px;
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        margin-top: -150px;
        margin-left: -200px;
        background-image: linear-gradient(to top, #4d6fbf, #2d3e69);
        border-radius: 15px;

        @width: 250px;

        .password-header {
            width: 100%;
            height: 50px;
            line-height: 50px;
            padding-top: 20px;
            color: #fff;

            >div {
                width: @width;
                height: 20px;
                line-height: 20px;
                margin: 0 auto;
                text-align: center;
                font-size: 14px;
            }

            >div:first-child {
                height: 40px;
                line-height: 40px;
                font-size: 20px;
            }
        }

        .password-main {
            width: @width;
            margin: 0 auto;
            margin-top: 20px;

            .el-button--primary {
                background: #2d3e69 !important;
                border-color: #2d3e69 !important;
                color: #FFF !important;

                .el-loading-mask {
                    background-color: transparent !important;
                }
            }

        }

    }
}
</style>